class Tracker {
  addToCart({ value }) {
    // Facebook add to cart check if fbq exists
    if (typeof window.fbq === 'function') {
      window.fbq('track', 'AddToCart', {
        currency: 'VND',
        value,
      })
    }
  }

  purchase({ value }) {
    // Facebook purchase check if fbq exists
    if (typeof window.fbq === 'function') {
      window.fbq('track', 'Purchase', {
        currency: 'VND',
        value,
      })
    }
  }

  lead({ eventId }) {
    // Facebook lead check if fbq exists
    if (typeof window.fbq === 'function') {
      window.fbq(
        'track',
        'Lead',
        {},
        {
          eventID: eventId,
        }
      )
    }
  }
}

const tracker = new Tracker()

export default tracker
