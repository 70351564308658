import 'twin.macro'

import * as React from 'react'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import HeroSection from '../components/PageSocola/HeroSection'
import CollectionSection from '../components/PageSocola/CollectionSection'
import Footer from '../components/Base/Footer'
import SEOComponent from '../components/SEOComponent'
import CartIcon from '../components/Base/CartIcon'

export default function SocolaPage() {
  return (
    <TailwindLayout tw="bg-white">
      <CartIcon />
      <HeroSection />
      <CollectionSection />
      <Footer
        gradient={'229.96deg, #F86486 32.28%, rgba(241, 61, 104, 0) 126.45%'}
      />
      <SEOComponent
        title="Socola Valentine's Day | Savor Cake"
        description="Hai mẫu socola truffle độc đáo, đậm đà và ngọt ngào, đáp ứng mọi nhu cầu của bạn và người thương."
        keywords="socola,truffle"
      />
    </TailwindLayout>
  )
}
