import { useCallback } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import {
  multiOrderAtom,
  orderItemsAtom,
  selectedProductIdAtom,
  selectedVariantSkuAtom,
} from '../states/selectedProduct'
import _, { includes } from 'lodash'
import { infoToast, successToast } from '../utils/toastify'
import { getBoxAccessory } from '../utils/utils'
import {
  CUSTOMIZABLE_PANNA_COTTA_SKU,
  cakeItemVariants,
} from '../utils/constants'
import tracker from '../utils/tracker'
import { navigate } from 'gatsby'
import { modalAddonsVisibleAtom } from '../states/modal.state'
import { birthdayCakeSkus, ENUM_1h_GROUP_NAME } from '../utils/products'
import dayjs from 'dayjs'
import useNoteCartChange from './useNoteCartChange'

const useOrderItems = () => {
  const [orderItems, setOrderItems] = useAtom(orderItemsAtom)
  const [, setModalAddonsVisible] = useAtom(modalAddonsVisibleAtom)
  const [, setSelectedProductId] = useAtom(selectedProductIdAtom)
  const [, setSelectedVariantSku] = useAtom(selectedVariantSkuAtom)
  const globalMultiOrder = useAtomValue(multiOrderAtom)
  const { saveCartChangeNote } = useNoteCartChange()

  const handleChangeItemQuantity = useCallback(
    ({ cartItem, newQuantity }) => {
      if (!newQuantity) {
        return
      }
      setOrderItems((preItems) =>
        _.map(preItems, (item) =>
          item.sku === cartItem.sku
            ? { ...item, quantity: parseInt(newQuantity) }
            : item
        )
      )
    },
    [setOrderItems]
  )

  const handleIncrementItem = useCallback(
    (cartItem) => {
      setOrderItems((preItems) =>
        _.map(preItems, (item) => {
          if (item.sku !== cartItem.sku) {
            return item
          }
          if (item.uid && item.uid !== cartItem.uid) {
            return item
          }

          return {
            ...item,
            quantity: cartItem.quantity + 1,
          }
        })
      )
    },
    [setOrderItems]
  )

  const handleDecrementItem = useCallback(
    (cartItem) => {
      setOrderItems((preItems) =>
        _.map(preItems, (item) => {
          if (item.sku !== cartItem.sku) {
            return item
          }
          if (item.uid && item.uid !== cartItem.uid) {
            return item
          }

          return {
            ...item,
            quantity: cartItem.quantity > 1 ? cartItem.quantity - 1 : 1,
          }
        })
      )
    },
    [setOrderItems]
  )
  const handleRemoveItem = useCallback(
    (cartItem) => {
      setOrderItems((preItems) => {
        let newArr = _.reject(preItems, (item) => {
          return cartItem.uid
            ? item.uid === cartItem.uid
            : item.sku === cartItem.sku
        })
        const matchBox =
          getBoxAccessory({
            cakeSizeName: cartItem.sizeName,
            cakeSku: cartItem.sku,
          }) || {}

        const cakesWithSameSize = _.filter(newArr, (item) => {
          return item.sizeName === cartItem.sizeName && !item.includedMica
        })
        const boxItemInCart = _.find(newArr, {
          sku: matchBox.sku,
        })
        if (!boxItemInCart) {
          return newArr
        }

        const cakesWithSameSizeQty = _.sumBy(cakesWithSameSize, 'quantity') || 0
        if (cakesWithSameSizeQty === 0) {
          infoToast('Đã bỏ hộp mica không cùng size bánh ra khỏi giỏ hàng')
          return _.reject(newArr, { sku: boxItemInCart.sku })
        }
        newArr = _.map(newArr, (item) => {
          if (item.sku !== matchBox.sku) {
            return item
          }
          item.quantity = cakesWithSameSizeQty
          return item
        })
        infoToast(
          'Đã cập nhật lại số lượng hộp mica cùng size bánh bạn vừa bỏ khỏi giỏ hàng'
        )
        return newArr
      })
      saveCartChangeNote(`Remove ${cartItem.sku} of ${cartItem.description}`)
    },
    [setOrderItems]
  )
  // NOTE: Check kĩ logic cho BSN thường đặt và các sản phẩm thời vụ khác
  // todo: cần xử lý thêm để limit số mica box có thể chọn theo số bánh hợp lệ
  const addToCart = ({ product, selectedVariant, isReplaced }) => {
    const addonAvail = checkProductAddonAvail({
      product,
      productVariant: selectedVariant,
    })
    const matchOrderItem = _.find(
      orderItems,
      (orderItem) =>
        orderItem.sku === selectedVariant.sku && orderItem.id === product.id
    )

    let customizedUid = null
    let uid = null
    if (selectedVariant.sku === CUSTOMIZABLE_PANNA_COTTA_SKU) {
      customizedUid = dayjs().valueOf()
    }
    if (addonAvail || product.isTextInBase !== false || product.isTextInCake) {
      uid = dayjs().valueOf()
    }

    setOrderItems((preOrderItems) => {
      /**
       * ko check panna tự chọn:
       * để tăng số lượng set GIỐNG kiểu kết hợp vị đã chọn có 2 cách:
       * 1 là tăng slg của item ở giỏ (không disable vì ko để uid)
       * 2 là thêm mới, TH này thì vẫn thêm item mới có uid và tất nhiên vẫn sửa slg được
       *  */
      if (!uid && matchOrderItem && !isReplaced) {
        return _.map(orderItems, (orderItem) => {
          if (orderItem.sku !== selectedVariant.sku) {
            return orderItem
          }
          return {
            ...orderItem,
            quantity: orderItem.quantity + 1,
          }
        })
      }
      const textVariants =
        product.isTextInCake || !product.hasOwnProperty('isTextInBase')
          ? _.compact([
              product.isTextInCake
                ? {
                    type: cakeItemVariants.TEXT_IN_CAKE,
                    description: '',
                  }
                : undefined,
              product.isTextInBase === false
                ? undefined
                : {
                    type: cakeItemVariants.TEXT_IN_BASE,
                    description: '',
                  },
            ])
          : undefined

      const selectedVariantTypes = _.map(selectedVariant.variants, 'type')
      selectedVariant.variants = selectedVariant.variants || []
      _.forEach(textVariants, (variant) => {
        if (_.includes(selectedVariantTypes, variant.type)) {
          return
        }
        selectedVariant.variants = selectedVariant.variants || []
        selectedVariant.variants.push(variant)
      })
      if (isReplaced) {
        preOrderItems = []
      }
      const newItem = {
        ...product,
        image: null,
        ...selectedVariant,
        productId: product.id,
        sku: selectedVariant.sku,
        originalPrice: selectedVariant.normalPrice || selectedVariant.salePrice,
        soldPrice: selectedVariant.salePrice || selectedVariant.normalPrice,
        quantity: 1,
      }

      if (
        includes(selectedVariantTypes, 'CHOUX_CREAM') &&
        newItem.prepareTime < 1.5
      ) {
        newItem.prepareTime = 1.5
        newItem.group =
          newItem.group === ENUM_1h_GROUP_NAME
            ? 'Bánh 1h upsize su kem'
            : newItem.group
      }

      newItem.customizedUid = customizedUid
      newItem.uid = uid

      return [...preOrderItems, newItem]
    })
  }

  const handleChangeDescription = ({
    cartItem,
    targetType,
    newDescription,
  }) => {
    const updatedItems = _.map(orderItems, (item) => {
      if (item.sku !== cartItem.sku) {
        return item
      }
      if (item.uid && item.uid !== cartItem.uid) {
        return item
      }
      const updatedVariants = _.map(item.variants, (variant) => {
        if (variant.type === targetType) {
          return { ...variant, description: newDescription }
        }
        return variant
      })
      return { ...item, variants: updatedVariants }
    })

    setOrderItems(updatedItems)
  }

  const addToCartAfterAddons = ({
    product,
    selectedVariant,
    multiOrder,
    noteContent,
  }) => {
    const finalMultiOrder =
      multiOrder !== undefined ? multiOrder : globalMultiOrder
    tracker.addToCart({
      value: selectedVariant.salePrice,
    })
    addToCart({ product, selectedVariant })
    successToast('🎉 Thêm sản phẩm vào giỏ hàng thành công')
    setModalAddonsVisible(false)
    setSelectedProductId(null)
    setSelectedVariantSku(null)
    const changeNote = `${noteContent} ${
      finalMultiOrder ? '(Multi)' : '(Single)'
    }`
    saveCartChangeNote(changeNote)
    if (!finalMultiOrder) {
      navigate('/checkout/')
    }
  }

  return {
    orderItems,
    addToCart,
    handleChangeItemQuantity,
    handleIncrementItem,
    handleDecrementItem,
    handleRemoveItem,
    handleChangeDescription,
    addToCartAfterAddons,
  }
}

export default useOrderItems

export const checkShowUpAddonSet = function ({
  setName,
  product = {},
  productVariant = {},
}) {
  switch (setName) {
    case 'Thêm bánh su kem':
      return (
        productVariant.sizeName &&
        ['mini', 'nhỏ'].includes(productVariant.sizeName) &&
        product.description &&
        !product.description.match(/panna|su kem/gim) &&
        !['Bánh cốc'].includes(product.group) &&
        !product.chouxComboException
      )
    case 'Thêm nhân':
      return (
        product.description &&
        !product.description.match(
          /panna|mousse|bltm|bông lan trứng muối|su kem|tiramisu/gim
        ) &&
        product.group &&
        product.group !== 'Bánh cốc' && // trừ mấy sp này thì đều ok
        _.includes(birthdayCakeSkus, productVariant.sku)
      )
    default:
      return false
  }
}
// Quyết định việc hiện modal addon hay ko
function checkProductAddonAvail({ product, productVariant }) {
  if (productVariant.sku === CUSTOMIZABLE_PANNA_COTTA_SKU) {
    return true
  }
  if (
    checkShowUpAddonSet({
      setName: 'Thêm bánh su kem',
      productVariant,
      product,
    })
  ) {
    return true
  }
  if (checkShowUpAddonSet({ setName: 'Thêm nhân', product, productVariant })) {
    return true
  }
  return false
}
function filterProductAddonAndSets({
  product = {},
  productVariant = {},
  sets = [],
  addons = [],
  showType, // hiện loại addonSets hoặc addon trong sets
}) {
  const showUpsizeChoux = checkShowUpAddonSet({
    setName: 'Thêm bánh su kem',
    product,
    productVariant,
  })

  if (productVariant.sku === CUSTOMIZABLE_PANNA_COTTA_SKU) {
    return showType && showType === 'set'
      ? _.filter(sets, { isPannaChoice: true })
      : addons
  }
  if (showType && showType === 'set') {
    return _.reject(sets, (set) => {
      // set panna chỉ hiện với type addon riêng cho mã panna tự chọn
      if (set.isPannaChoice) {
        return true
      }
      // reject set thêm su nếu KHÔNG upsize được
      if (set.setName === 'Thêm bánh su kem') {
        return !showUpsizeChoux
      }
      // reject set thêm nhân nếu KHÔNG thêm được nhân
      return !checkShowUpAddonSet({
        setName: 'Thêm nhân',
        productVariant,
        product,
      })
    })
  }
  return _.filter(addons, (addon) => {
    if (addon.cakeSizeName === 'all') {
      return showUpsizeChoux
    }
    return addon.cakeSizeName === productVariant.sizeName
  })
}

export { filterProductAddonAndSets, checkProductAddonAvail }
