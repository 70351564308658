import { styled } from 'twin.macro'
import * as React from 'react'

import TopMenu from './TopMenu'
import { StaticImage } from 'gatsby-plugin-image'

import mobileBg from '../../assets/images/landing-page-20-10/hero-m.webp'
import desktopBg from '../../assets/images/landing-page-20-10/hero-bg-desktop.webp'
import '../../assets/styles/fonts.css'

export default function HeroSection() {
  return (
    <HeroSectionWrapper tw="">
      <div div tw="p-0 mx-auto 2xl:pt-8 lg:container">
        <TopMenu />
        <div
          tw="flex flex-col
            mx-auto overflow-hidden
            lg:grid lg:grid-cols-11 lg:grid-rows-none
            p-2.5 lg:px-12 2xl:py-8"
        >
          <h1
            tw="text-center lg:text-left
              row-span-1 row-start-1 lg:col-span-6 lg:row-span-1 lg:col-start-1 lg:row-start-1
              font-20t10-heading text-20t10-pink-800 font-normal
              m-0 pt-4 md:pt-10 md:pb-6 lg:pl-16
              text-2xl md:text-3xl lg:text-4xl!

              "
          >
            Socola Truffle cho
          </h1>
          <h2
            tw="text-center row-span-1 row-start-2 font-20t10-hero-heading text-white font-normal
            leading-10 text-5xl sm:text-6xl xl:text-7xl! 2xl:text-8xl!
            lg:col-span-6 lg:row-span-1 lg:col-start-1 lg:row-start-2 lg:pr-12
            mt-5 2xl:p-0
            "
          >
            Valentine's Day
          </h2>
          <p
            tw="row-span-1 row-start-3 lg:col-span-6 lg:row-span-1 lg:col-start-1 lg:row-start-3
            text-center text-white font-savor-cake-secondary-quicksand tracking-wider font-semibold
            sm:px-20 lg:px-0 2xl:px-8 2xl:py-8
            text-base sm:text-lg md:text-xl lg:text-2xl
            "
          >
            Chọn ngay mẫu socola ưng ý cho nửa kia của bạn. Hoặc tự thưởng cho
            mình một chút hương vị ngọt ngào nhé!
          </p>
          <div
            tw="
              lg:col-span-6 lg:row-span-3 lg:row-start-2 lg:col-start-7
              m-auto
              w-9/10 sm:w-4/5 lg:w-full xl:w-9/10
              "
          >
            <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/socola-hero.png"></StaticImage>
          </div>
          {/* <div tw="lg:col-start-1 lg:col-span-6 flex justify-center w-full">
              <motion.div variants={buttonVariants} whileHover="hover">
                <button
                  tw="row-span-1 row-start-5 bg-linear-gradient-20t10-dark-pink w-64 h-14 text-white text-base rounded-100
            m-auto font-savor-cake-secondary-quicksand font-bold
            sm:mt-12 xl:mt-0
            lg:col-span-6 lg:row-span-1 lg:col-start-1
            2xl:text-3xl 2xl:w-96 2xl:h-20
            "
                >
                  ĐẶT NGAY
                </button>
              </motion.div>
            </div> */}
        </div>
      </div>
      <div tw="w-full lg:hidden">
        <StaticImage
          src="../../assets/images/landing-page-20-10/vector.png"
          alt="decor"
        ></StaticImage>
      </div>
      <div tw="hidden lg:block w-full">
        <StaticImage
          src="../../assets/images/landing-page-20-10/vector-desktop.png"
          alt="decor 2"
        ></StaticImage>
      </div>
    </HeroSectionWrapper>
  )
}

const HeroSectionWrapper = styled.div`
  background-image: url(${mobileBg});
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: 1024px) {
    background-image: url(${desktopBg});
  }
`
