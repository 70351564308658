import 'twin.macro'
import tw, { styled } from 'twin.macro'
import * as React from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'gatsby'
// import { StaticImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import ImageZoom from 'react-medium-image-zoom'
// import everfall from '../../assets/svg/everfall.svg'
// import heart from '../../assets/svg/heart.svg'

import { buttonVariants } from '../../utils/motion'
import useOrderItems from '../../hooks/useOrderItems'
const data = [
  // {
  //   id: 'truffle-9v',
  //   content: {
  //     title: 'Socola Truffle 9 viên',
  //     desc: 'Vuông vắn như tình yêu của bạn với mức giá vô cùng hợp lý.',
  //     price: '149.000đ',
  //   },
  //   imageLink: {
  //     desktopImage: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/9v-doc-2.jpg"
  //         alt="Socola Truffle 9 viên dọc"
  //         aspectRatio={2 / 3.2}
  //       />
  //     ),
  //     normalImage1: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/9v-2.jpg"
  //         alt="Socola Truffle 9 viên"
  //         aspectRatio={10 / 10}
  //       />
  //     ),
  //     normalImage2: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/9v-3.jpg"
  //         alt="Socola Truffle 9 viên"
  //         aspectRatio={10 / 10}
  //       />
  //     ),
  //     mobileImage: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/9v.jpg"
  //         alt="Socola Truffle 9 viên"
  //         aspectRatio={10 / 10}
  //       />
  //     ),
  //   },
  //   directionVariant: false,
  // },
  // {
  //   id: 'truffle-12v',
  //   content: {
  //     title: 'Socola Truffle 12 viên',
  //     desc: 'Đủ để chia sẻ với người thương và cũng đủ để bạn tự thưởng.',
  //     price: '199.000đ',
  //   },
  //   imageLink: {
  //     desktopImage: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/12v-4.jpg"
  //         alt="Socola Truffle 12 viên"
  //         aspectRatio={2 / 3}
  //       />
  //     ),
  //     mobileImage: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/12v.jpg"
  //         alt="Socola Truffle 12 viên"
  //         aspectRatio={1 / 1}
  //         objectPosition="center"
  //       />
  //     ),
  //     normalImage1: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/12v-2.jpg"
  //         alt="Socola Truffle 12 viên"
  //         aspectRatio={1 / 1}
  //         objectPosition="center"
  //       />
  //     ),
  //     normalImage2: (
  //       <StaticImage
  //         src="https://s3.kstorage.vn/savor-web/src/assets/images/page-socola/12v-3.jpg"
  //         alt="Socola Truffle 12 viên"
  //         aspectRatio={1 / 1}
  //         objectPosition="center"
  //       />
  //     ),
  //   },
  //   directionVariant: true,
  // },
]

const P = styled.p`
  ${tw`text-center text-base md:text-2xl font-medium text-20t10-red-1000 font-savor-cake-secondary-quicksand
  mx-auto sm:w-3/4 lg:w-2/4 mb-8`}
`

const ListItem = styled.li`
  ${tw`text-center text-base md:text-2xl font-medium text-20t10-red-1000 font-savor-cake-secondary-quicksand
  mx-auto sm:w-3/4 lg:w-2/4 mb-2`}
`

export default function CollectionSection() {
  return (
    <Container id="collection">
      <div
        tw="lg:container p-0 mx-auto px-4
        lg:max-w-screen-xl!
        "
      >
        <h2
          tw="w-72 md:w-10/12 mx-auto md:mb-8 text-4xl md:text-6xl
        font-bold text-center text-20t10-pink-800 font-20t10-heading"
        >
          Socola Truffle
        </h2>
        <P>
          Nhân Socola Truffle được làm từ Socola nguyên chất 74%, mang lại vị
          đắng nhẹ xen lẫn vị ngọt, béo, ngậy. Đặc biệt socola không bị cứng như
          các loại truyền thống.
        </P>
        <P>Cả hai hộp đều có 5 loại trang trí phủ cho bạn thưởng thức gồm:</P>
        <ul
          tw="text-center text-base md:text-2xl font-medium text-20t10-red-1000 font-savor-cake-secondary-quicksand
        mx-auto sm:w-3/4 lg:w-2/4 mb-8"
        >
          <ListItem>🍫 Truffle cacao</ListItem>
          <ListItem>🌿 Truffle matcha</ListItem>
          <ListItem>🥥 Truffle dừa</ListItem>
          <ListItem>🌕 Truffle dát vàng</ListItem>
          <ListItem>🤍 Truffle sọc socola trắng</ListItem>
        </ul>
        <P>Cả 2 sản phẩm đã bao gồm thiệp và túi đựng!</P>
        <div tw="max-w-md md:max-w-5xl mx-auto">
          {data.map((item) => {
            return (
              <CollectionItem
                productId={item.id}
                item={item}
                key={item.content.title}
              />
            )
          })}
        </div>
        <div tw="flex flex-row flex-wrap justify-center items-start gap-2 mx-4 pb-10">
          <Link to="/#scake-truffle">
            <Button
              tw="bg-savor-cake-primary-green-avocado-0!
      text-white! font-savor-cake-secondary-quicksand!"
            >
              Vui lòng xem mẫu 2024 mới tại đây
            </Button>
          </Link>
        </div>
      </div>
    </Container>
  )
}

const CollectionItem = ({ item }) => {
  const { addToCartAfterAddons } = useOrderItems()
  return (
    <div
      tw=" mx-auto grid grid-cols-2 md:grid-cols-10 grid-flow-row md:grid-flow-col gap-1
      mt-2
      text-center mb-16 z-10 font-savor-cake-secondary-quicksand"
    >
      <VerticalImage directionVariant={item.directionVariant}>
        <ImageZoom>{item.imageLink.desktopImage}</ImageZoom>
      </VerticalImage>
      <div tw="z-10 bg-20t10-pink-300 flex justify-center items-center row-span-3 md:row-span-1 md:col-span-3">
        <ImageZoom>{item.imageLink.normalImage1}</ImageZoom>
      </div>
      <div tw="z-10 bg-20t10-pink-300 flex justify-center items-center row-span-3 md:row-span-1 md:col-span-3">
        <ImageZoom>{item.imageLink.normalImage2}</ImageZoom>
      </div>
      <HorizontalImage directionVariant={item.directionVariant}>
        <ImageZoom>{item.imageLink.mobileImage}</ImageZoom>
      </HorizontalImage>
      <Content directionVariant={item.directionVariant}>
        <h3 tw="text-2xl text-20t10-pink-800 font-20t10-heading lg:text-3xl md:px-2 lg:px-6 pt-4 font-semibold">
          {item.content.title}
        </h3>
        <p tw="w-72 mx-auto text-base text-20t10-red-1000 md:w-52 lg:w-64 lg:text-xl text-center font-normal mt-0!">
          {item.content.desc}
        </p>
        <p tw="text-20t10-red-1000 font-bold text-2xl">{item.content.price}</p>
        <motion.div variants={buttonVariants} whileHover="hover">
          <button
            onClick={addToCartAfterAddons}
            tw="relative w-48 h-12 rounded-full cursor-pointer text-xl font-bold text-white
            flex justify-center items-center mb-5 mx-auto
            bg-linear-gradient-20t10-light-pink z-10
            before:bg-linear-gradient-20t10-dark-pink before:-z-1 before:absolute before:top-0
            before:left-0 before:bottom-0 before:right-0 before:content-[''] before:transition-opacity
            before:opacity-0 before:ease-linear before:duration-300 before:rounded-full hover:before:opacity-100"
          >
            Thêm vào giỏ
          </button>
        </motion.div>
      </Content>
    </div>
  )
}

const HorizontalImage = styled.div`
  ${({ directionVariant = false }) =>
    directionVariant === true
      ? tw`md:hidden flex justify-center row-start-1 row-end-4 col-span-2`
      : tw`md:hidden flex justify-center row-span-3 col-span-2`}
`
const VerticalImage = styled.div`
  ${({ directionVariant = false }) =>
    directionVariant === true
      ? tw`bg-20t10-pink-300 z-10 md:block hidden md:row-span-2 md:col-start-7 md:col-span-4`
      : tw`bg-20t10-pink-300 z-10 md:block hidden md:row-span-2 md:col-start-1 md:col-end-5`}
`
const Content = styled.div`
  ${({ directionVariant = false }) =>
    directionVariant === true
      ? tw`flex justify-center items-center space-y-4 flex-col row-span-3 col-span-2 md:col-span-3 md:row-span-2 md:col-start-1`
      : tw`flex justify-center items-center space-y-4 flex-col row-span-3 col-span-2 md:col-span-3 md:row-span-2`}
`
const Container = styled.div`
  @media (min-width: 1024px) {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 500px;
      height: 500px;
      top: 0;
      right: 0;
      background-size: cover;
      background-position: center;
    }
    &:after {
      content: '';
      position: absolute;
      width: 500px;
      height: 500px;
      bottom: 13%;
      left: 0%;
      background-size: cover;
      background-position: center;
    }
  }
`
