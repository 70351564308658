const useNoteCartChange = () => {
  const saveCartChangeNote = (content) => {
    const newNote = {
      content,
      time: new Date(),
    }

    const existingNotes = JSON.parse(localStorage.getItem('cartNotes')) || []
    existingNotes.push(newNote)
    localStorage.setItem('cartNotes', JSON.stringify(existingNotes))
  }

  return {
    saveCartChangeNote,
  }
}

export default useNoteCartChange
