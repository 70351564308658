import 'twin.macro'
import * as React from 'react'
// import { useEffect, useRef } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function TopMenu() {
  // const [showMenu, setShowMenu] = React.useState(false)
  // const menuItems = [
  //   { label: 'Các mẫu socola', id: 'collection' },
  //   { label: 'Đặt bánh', id: 'order' },
  // ]

  // function useOutsideAlerter(ref) {
  //   useEffect(() => {
  //     function handleClickOutside(event) {
  //       if (ref.current && !ref.current.contains(event.target)) {
  //         setShowMenu(false)
  //       }
  //     }
  //     document.addEventListener('mousedown', handleClickOutside)
  //     return () => {
  //       document.removeEventListener('mousedown', handleClickOutside)
  //     }
  //   }, [ref])
  // }
  // const wrapperRef = useRef(null)
  // useOutsideAlerter(wrapperRef)

  return (
    <div tw="flex mx-auto justify-center pt-6 md:pt-12 px-4 lg:px-20">
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cakes-logo-green.png"
        alt="logo"
        width={100}
      ></StaticImage>
    </div>
  )
}
